<template>
  <div class="account-users">
    <school />
    <school-address />
    <school-bank v-if="hasModule('finance')" />
  </div>
</template>

<script>
import showToast from '@/lenon/mixins/showToast'
import { FETCH_BANKS_ADDRESSES_Q } from '@/graphql/queries'
import School from '@/views/admin/school-account/School.vue'
import SchoolAddress from '@/views/admin/school-account/Address.vue'
import SchoolBank from '@/views/admin/school-account/Bank.vue'

export default {
  name: 'SchoolAccount',
  components: {
    SchoolBank,
    SchoolAddress,
    School,
  },
  mixins: [showToast],
  data() {
    return {
    }
  },
  mounted() {
    this.fetchBanksAddresses()
  },
  methods: {
    hasModule(module) {
      const modules = this.$store.getters['auth/schoolModules']
      return modules.includes(module)
    },
    fetchBanksAddresses() {
      this.$apollo.query({ query: FETCH_BANKS_ADDRESSES_Q })
        .then(res => {
          this.$store.commit('auth/setBanksAddresses', res.data)
        })
    },
  },
}
</script>
